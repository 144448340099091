<template>
    <Dropdown
        v-model="value"
        :options="options"
        optionLabel="label"
        optionValue="value"
        :placeholder="placeholder">
        <template #value="slotProps">
            <FlaggedLanguage :lang='slotProps.value'/>
        </template>
        <template #option="slotProps">
            <FlaggedLanguage :lang='slotProps.option.value'/>
        </template>
    </Dropdown>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const commonStore = createNamespacedHelpers('Common');
import FlaggedLanguage from '../FlaggedLanguage';

export default {
    components: { FlaggedLanguage },
    data(){
        return {};
    },
    props: ['modelValue', 'placeholder'],
    emits: ['update:modelValue'],
    computed: {
        ...commonStore.mapGetters(['languages']),
        options(){
            return Object.keys(this.languages).map(k => {
                return { value: k, label: this.languages[k].label }
            })
        },
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>

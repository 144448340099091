<template>
    <div class='grid' v-if='company_id && dd_companies'>
        <ConfirmDialog></ConfirmDialog>
        <Dialog v-model:visible='newUserEdit'>
            <template #header>
                <h6 v-if='editData.id'>Crea un abbonamento a <strong style='color: red'>{{ editData.email }}</strong>
                </h6>
                <h6 v-else>Cerca l'utente</h6>
            </template>
            <SearchUser @onChoose='onChose($event)' :resetOnChoose='true' :delay='1300'
                        v-if='!editData.id'></SearchUser>
            <div class='grid p-fluid mt-5' v-else>
                <div class='col-12'>

                    <div class='grid p-fluid formgrid' v-if='team_id===0'>
                        <div class='field col-12' v-if='teams.length > 0'>
                            <div class='p-inputgroup'>
                                <Button :icon='iconLoadingTeams' class='p-button-warning'
                                        @click='teamsAlreadyUpdated' />
                                <span class='p-float-label'>
                            <Dropdown
                                v-model='editData.team_id'
                                id='team_id'
                                dataKey='team_id'
                                optionLabel='label'
                                :options='teams'
                                option-value='id'
                                :filter='true'
                                filterPlaceholder='Cerca' />
                            <label for='team_id'>Team</label>
                        </span>
                            </div>
                        </div>
                    </div>

                    <div class='grid p-fluid formgrid mt-3'>
                        <div class='field col-7'>
                            <LanguageDropdown v-model='editData.lang' placeholder='Lingua' />
                            <label class='sup'>Lingua utente</label>
                        </div>

                        <div class='field col-5'>
                            <BoolDropdown v-model='editData.email_marketing' placeholder='Email Marketing' />
                            <label class='sup'>Marketing</label>
                        </div>
                    </div>

                    <div class='grid p-fluid formgrid'>
                        <div class='field col-12'>
                            <span class='p-float-label'>
                                <PlansDropdown v-model='editData.plan_id'
                                               placeholder='Piano' />
                                <label>Piano</label>
                            </span>
                        </div>
                    </div>

                    <div class='grid p-fluid formgrid mt-3'>
                        <div class='col-6'>
                            <span class='p-float-label'>
                                <InputNumber
                                    id='duration'
                                    mode='decimal'
                                    :useGrouping='false'
                                    :maxFractionDigits='0'
                                    v-model='editData.duration'
                                />
                                <label for='duration'>Durata (gg)</label>
                            </span>
                        </div>
                    </div>
                </div>


            </div>
            <template #footer>
                <SaveButton @click='onSubAdd' :load='loading' />
                <ResetButton @click='closeAddEditor' :load='loading' />
            </template>
        </Dialog>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Elenco degli utenti di {{ companyName }} {{extraTitle}}</h4>
                </div>
                <DataTable :value='rows' :paginator='true' class='p-datatable-gridlines' :rows='10' dataKey='id'
                           :rowHover='true'
                           v-model:filters='filters' filterDisplay='menu' :loading='loading'
                           responsiveLayout='scroll'
                >
                    <template #header>
                        <ListHeader
                            @clear='clearFilter()'
                            v-model="filters['global'].value"
                            @add='openAddEditor'
                            @refresh='refreshList()'
                            :loading='loading'
                        />
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>


                    <Column field='user.email' header='Email' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.user.email }}
                        </template>
                    </Column>

                    <Column field='user.last_name' header='Nome' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.user.first_name + ' ' + slotProps.data.user.last_name }}
                        </template>
                    </Column>

                    <Column field='team_id' header='Team' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            <template v-if='slotProps.data.user.id !== changingTeam'>
                                <font-awesome-icon
                                    class='pointer ml-2 text-orange-500'
                                    title='Modifica'
                                    :icon="['fas', 'edit']"
                                    size='1x'
                                    @click='onChangeTeamStart(slotProps.data.user.id, slotProps.data.team_id)'
                                />
                                {{ teamName(slotProps.data.team_id) }}
                            </template>
                            <template v-else>
                                <div class='grid p-fluid formgrid mt-3'>
                                    <div class='field col-12' v-if='teams.length > 0'>
                                        <div class='p-inputgroup'>
                                            <Button icon='pi pi-check'
                                                    title='Salva Modifica'
                                                    class='p-button-success'
                                                    @click='requestTeamChange' />
                                            <span class='p-float-label'>
                                                <Dropdown
                                                    v-model='editTeam'
                                                    id='mod_team_id'
                                                    dataKey='team_id'
                                                    optionLabel='label'
                                                    :options='teams'
                                                    option-value='id'
                                                    :filter='true'
                                                    filterPlaceholder='Cerca' />
                                                <label for='mod_team_id'>Team</label>
                                            </span>
                                            <Button icon='pi pi-times'
                                                    title='Annulla Modifica'
                                                    class='p-button-danger'
                                                    @click='onChangeTeamStop' />
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </Column>

                    <Column field='date_in' header='Ins' :sortable='true'>
                        <template #body='slotProps'>
                            {{ ts2date(slotProps.data.date_in) }}
                        </template>
                    </Column>

                    <Column field='subscription.Expiry' header='Expiry' :sortable='true'>
                        <template #body='slotProps'>
                            <template v-if='changingSubscription === slotProps.data.user.id'>
                                <div class='p-inputgroup'>
                                    <Button icon='pi pi-check'
                                            title='Salva Modifica'
                                            class='p-button-success'
                                            @click='requestExpiryChange(slotProps.data.subscription)' />
                                    <Calendar
                                        :showTime='true'
                                        inputId='expiry_at'
                                        v-model='editSubscriptionDate'
                                        dateFormat='dd/mm/yy' />
                                    <Button icon='pi pi-times'
                                            title='Annulla Modifica'
                                            class='p-button-danger'
                                            @click='onChangeExpiryStop' />
                                </div>
                            </template>
                            <template v-else>
                                <font-awesome-icon
                                    class='pointer ml-2 text-orange-500'
                                    title='Modifica'
                                    :icon="['fas', 'edit']"
                                    size='1x'
                                    @click='onChangeSubscriptionExpiryStart(slotProps.data.subscription.Expiry, slotProps.data.user.id)'
                                />
                                UTC {{ ts2date(slotProps.data.subscription.Expiry) }}
                            </template>
                        </template>
                    </Column>

                    <Column field='id'>
                        <template #body='slotProps'>
                            <font-awesome-icon
                               @click='requestDeleteSubscription(slotProps.data.subscription.Id)'
                               class='text-pink-600 hover:text-pink-400 pointer ml-1'
                               title='Rimuovi subscription e associazione'
                               :style='{marginBottom: "5px", fontSize: "37px"}'
                               :icon="['fas', 'trash']"
                               size='2x'
                               />
                        </template>
                    </Column>

                </DataTable>

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Users/CompanyUsers');
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';

import ListHeader from '../../../components/4books/ListHeader';
import dd_companies from '../../../mixins/dd_companies';

const commonStore = createNamespacedHelpers('Common');
const usersStore = createNamespacedHelpers('Users');

import SearchUser from '../../../components/4books/SearchUser';
import ResetButton from '../../../components/4books/forms/ResetButton.vue';
import tools from '../../../libs/tools';
import LanguageDropdown from '../../../components/4books/forms/LanguageDropdown';
import BoolDropdown from '../../../components/4books/forms/BoolDropdown';
import PlansDropdown from '../../../components/4books/forms/PlansDropdown';

export default {
    components: {
        ResetButton,
        ListHeader,
        SearchUser,
        LanguageDropdown,
        BoolDropdown,
        PlansDropdown,
    },
    data() {
        return {
            filters: null,
            newUserEdit: false,
            editData: {},
            teams: [],
            reloadingTeams: false,
            changingTeam: 0,
            editTeam: 0,
            changingSubscription: 0,
            editSubscriptionDate: null,
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading']),
        ...usersStore.mapGetters(['CompanyTeams/rows']),
        iconLoadingTeams() {
            if (this.reloadingTeams) return 'pi pi-spin pi-spinner';
            return 'pi pi-sitemap';
        },
        companyName() {
            if (this.company_id > 0) {
                const company = this.getCompany(this.company_id);
                if (company) return company.name;
            }
            return 'Team ' + this.company_id;
        },
        extraTitle(){
            if (!this.team_id) return "";
            return "(Team "+this.teamName(this.team_id)+")";
        }
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
        this.reloadTeams(this.company_id);
    },
    methods: {
        ...mapActions(['fetchList', 'saveNewUser', 'changeUserTeam', 'deleteCompanySubscription']),
        ...commonStore.mapMutations(['setExtraBreadcrumbs']),
        ...usersStore.mapActions(['CompanyTeams/fetchList', 'Users/updateUserSubscription']),

        teamName(id) {
            if (!this.teams) return "";
            return tools.models.optionLabel(this.teams, id);
        },
        requestDeleteSubscription(sub_id){
            this.$confirm.require({
                message: 'Sei sicuro di voler eliminare questa subscription? Dopo l\'eliminazione occorre assicurarsi di rimuovere l\'associazione anche su intercom',
                header: 'Operazione non annullabile',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.doDeleteSubscription(sub_id);
                    this.$confirm.close();
                },
                reject: () => {
                    this.$confirm.close();
                },
            });
        },
        doDeleteSubscription(sub_id) {
            this.deleteCompanySubscription({
                company_id: this.company_id,
                subscription_id: sub_id
            }).then(() => {
                this.ShowSuccess('Ottimo', 'Subscription eliminata con successo.');
                this.ShowWarning('Attenzione', 'Controllare associazione Azienda/utente su Intercom.');
                this.refreshList();
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        requestExpiryChange(sub) {
            if (!sub) {
                this.ShowError('ATTENZIONE', 'Subscription inesistente');
                return;
            }
            this['Users/updateUserSubscription']({
                id: sub.Id,
                expiry: this.editSubscriptionDate,
                user_id: this.changingSubscription,
            })
                .then(() => {
                    this.ShowSuccess('Ottimo', 'Subscription modificata con successo');
                    this.refreshList();
                })
                .catch(err => {
                    this.ShowStandardError(err.response);
                }).finally(() => {
                this.onChangeExpiryStop();
            });
        },
        requestTeamChange() {
            this.changeUserTeam({
                company_id: this.company_id,
                new_team_id: this.editTeam,
                user_id: this.changingTeam,
            }).then(() => {
                this.ShowSuccess('Perfetto', 'Il team è stato cambiato');
                this.refreshList();
            }).catch(err => {
                this.ShowStandardError(err.response);
            }).finally(() => {
                this.onChangeTeamStop();
            });
        },
        validate() {
            if (this.company_id) this.editData.company_id = this.company_id;
            if (this.team_id) this.editData.team_id = this.team_id;

            if (!this.editData.company_id) {
                this.ShowWarning('Attenzione', 'Manca l\'azienda');
                return false;
            }
            if (!this.editData.team_id) {
                this.ShowWarning('Attenzione', 'Manca il Team');
                return false;
            }
            if (!this.editData.lang) {
                this.ShowWarning('Attenzione', 'Manca la lingua');
                return false;
            }
            if (!this.editData.plan_id) {
                this.ShowWarning('Attenzione', 'Manca il Piano');
                return false;
            }

            return true;
        },

        setPageBreadcrumbs() {
            if (this.team_id === 0){
                this.setExtraBreadcrumbs([
                    { label: this.companyName, to: '/app/users/companies/' + this.company_id },
                    { label: 'Users' },
                ]);
            } else {
                if (!this.teams) return;
                this.setExtraBreadcrumbs([
                    { label: this.companyName, to: '/app/users/companies/' + this.company_id },
                    { label: "Teams", to: '/app/users/companies/'+this.company_id+'/company_teams'},
                    { label: this.teamName(this.team_id), to: '/app/users/companies/'+this.company_id+'/company_teams/' + this.team_id },
                    { label: 'Users' },
                ]);
            }
        },
        teamsAlreadyUpdated() {
            this.ShowSuccess('Dati aggiornati', 'L\'elenco dei teams è gia aggiornato');
        },
        onChangeSubscriptionExpiryStart(expiry, user_id) {
            this.changingSubscription = user_id;
            this.editSubscriptionDate = new Date(expiry);
        },
        onChangeTeamStart(id, actual) {
            this.changingTeam = id;
            this.editTeam = actual;
        },
        onChangeTeamStop() {
            this.changingTeam = 0;
            this.editTeam = 0;
        },
        onChangeExpiryStop() {
            this.changingSubscription = 0;
            this.editSubscriptionDate = null;
        },
        reloadTeams(n) {
            this.reloadingTeams = false;
            if (!n) {
                return;
            }
            this.reloading = true;
            this['CompanyTeams/fetchList'](n).then(items => {
                this.teams = tools.models.listsToDropdown(items);
            }).catch(err => {
                this.ShowStandardError(err.response);
            }).finally(() => {
                this.reloadingTeams = false;
                this.setPageBreadcrumbs();
            });
        },
        onChose(data) {
            this.editData = data;
            this.editData['lang'] = 'it';
            this.email_marketing = true;
        },
        onSubAdd() {
            if (!this.validate()) {
                return;
            }
            this.saveNewUser(this.editData).then(() => {
                this.ShowSuccess('Perfetto', 'Subscription aggiunta');
                this.refreshList();
            }).catch(err => {
                this.ShowStandardError(err.response);
            }).finally(() => {
                this.closeAddEditor();
            });
        },
        refreshList() {
            this.fetchList({
                company_id: this.company_id,
                team_id: this.team_id,
            }).then(() => {
                console.log('ROWS', this.rows);
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        openAddEditor() {
            this.newUserEdit = true;
            this.editData = {};
        },
        closeAddEditor() {
            this.newUserEdit = false;
            this.editData = {};
        },
    },
    props: {
        company_id: {
            default: 0,
            type: Number,
        },
        team_id: {
            default: 0,
            type: Number,
        },
    },

    watch: {
        company_id(n) {
            this.refreshList();
            this.reloadTeams(n);
        },
        team_id() {
            this.refreshList();
        },
        dd_mapped_companies() {
            this.setPageBreadcrumbs();
        },
    },
    mixins: [
        Notifications,
        Navigations,
        dd_companies,
    ],
};
</script>

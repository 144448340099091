<template>
    <span>
        <Flag v-if='lang' :code="lang" :name="language" :width='24' :class="{'mr-1': true}"/>
        {{ language }}
    </span>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const commonStore = createNamespacedHelpers('Common');

export default {
    props: ['lang'],
    computed: {
        ...commonStore.mapGetters(['languages']),
        language(){
            if (this.lang) return this.languages[this.lang].label;
            return "";
        }
    }
}
</script>
